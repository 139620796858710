import React from 'react';
import Seo from 'modules/app/components/Seo';
import { PricingPage } from 'modules/pricing';
import { CookieConsent, PublicLayout } from '../modules/app';
import useSiteMetadata from 'modules/app/graphql/useSiteMetadata';

const Pricing: React.FC<WebPageProps> = ({ pageContext }) => {
  const { siteUrl } = useSiteMetadata();
  const { intl } = pageContext;
  const path = `${siteUrl}/${intl.language}${intl.originalPath}`;

  return (
    <>
      <Seo
        url={path}
        title="Pricing | Free for Talents, Scalable for Companies - Roango"
        description="Roango is free until the end of 2021 for all companies that sign up. A fair price model that fits and scales with your company will be published soon."
        image={`${siteUrl}/roango-share-image.png`}
      />

      <PublicLayout>
        <PricingPage />
      </PublicLayout>

      <CookieConsent />
    </>
  );
};

export default Pricing;
