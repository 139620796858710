import { css } from '@emotion/react';
import { breakpoints } from 'style/variables';

export const gridContainer = css`
  @media ${breakpoints.medium} {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    column-gap: 32px;
  }
`;
