import { css } from '@emotion/react';
import { spacing } from '@prototyp/gatsby-plugin-gumball/mixins';
import { breakpoints } from 'style/variables';

export const root = css`
  min-height: 100vh;
  background: hsl(var(--color-grayscale-9));
`;

export const wrapper = css`
  max-width: 1312px;
  margin: 0 auto;
  padding: ${spacing(40)} ${spacing(20)} ${spacing(40)};
  box-sizing: content-box;

  @media ${breakpoints.medium} {
    padding: ${spacing(120)} ${spacing(20)} ${spacing(120)};
  }

  @media ${breakpoints.large} {
    padding-bottom: ${spacing(160)};
  }
`;
