import React from 'react';
import * as styles from '../styles/PricingModel.styles';
import { motion } from 'framer-motion';
import { FADE_IN_MOVE_Y_CONTROLLED } from 'style/animations.config';

import Checkmark from 'assets/icons/checkDark.svg';
import { FormattedMessage } from 'gatsby-plugin-intl';

export const PricingModel: React.FC = () => {
  const list = [
    'pricingPage.list.1',
    'pricingPage.list.2',
    'pricingPage.list.3',
    'pricingPage.list.4',
    'pricingPage.list.5',
    'pricingPage.list.6',
    'pricingPage.list.7',
    'pricingPage.list.8',
    'pricingPage.list.9',
    'pricingPage.list.10',
    'pricingPage.list.11',
    'pricingPage.list.12',
  ];

  return (
    <div css={styles.root}>
      {list.map((item, i) => (
        <motion.div
          variants={FADE_IN_MOVE_Y_CONTROLLED}
          key={i}
          css={styles.item}
        >
          <div css={styles.check}>
            <Checkmark />
          </div>
          <p>
            <FormattedMessage id={item} />
          </p>
        </motion.div>
      ))}
    </div>
  );
};
