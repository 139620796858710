import React from 'react';
import { PricingModel } from './PricingModel';

import * as grid from '../styles/Grid.styles';
import * as styles from '../styles/PricingModels.styles';
import { motion } from 'framer-motion';
import { CtaButton, useOnScrollAnimation } from 'modules/app';
import { FADE_IN_MOVE_Y_CONTROLLED } from 'style/animations.config';
import { FormattedMessage } from 'gatsby-plugin-intl';

export const PricingModels: React.FC = () => {
  const root = useOnScrollAnimation({ threshold: 0 });

  const ROOT_VARIANT = {
    visible: {
      transition: {
        staggerChildren: 0.2,
        delayChildren: 0.2,
      },
    },
  };

  return (
    <motion.article
      ref={root.ref}
      variants={ROOT_VARIANT}
      initial="hidden"
      animate={root.animation}
      css={grid.gridContainer}
    >
      <div css={styles.root}>
        <div>
          <motion.p variants={FADE_IN_MOVE_Y_CONTROLLED} css={styles.label}>
            <FormattedMessage id="pricingPage.plan" />
          </motion.p>
          <motion.div variants={FADE_IN_MOVE_Y_CONTROLLED} css={styles.price}>
            <p css={styles.priceNumber}>125 EUR</p>
            <motion.div
              whileInView={{ height: 40 }}
              initial={{ height: 0 }}
              transition={{
                delay: 0.8,
                duration: 1.5,
                ease: [0.77, 0.0, 0.175, 1.0],
              }}
              css={styles.divider}
            />
            <p css={styles.priceText}>
              <FormattedMessage
                id="pricingPage.text"
                values={{
                  monthly: (
                    <span>
                      <FormattedMessage id="pricingPage.monthly" />
                    </span>
                  ),
                }}
              />
            </p>
          </motion.div>
          <motion.div variants={FADE_IN_MOVE_Y_CONTROLLED}>
            <CtaButton
              textId="Start a 3-month trial"
              path="/register"
              theme="dark"
              buttonType="primary"
              customStyles={styles.ctaBtn}
            />
          </motion.div>
          <motion.p variants={FADE_IN_MOVE_Y_CONTROLLED} css={styles.card}>
            <FormattedMessage id="pricingPage.noCard" />
          </motion.p>
        </div>

        <motion.p variants={FADE_IN_MOVE_Y_CONTROLLED} css={styles.desktopDemo}>
          <FormattedMessage
            id="pricingPage.demo"
            values={{
              link: (
                <a
                  href="https://app.apollo.io/#/meet/zeljko_prsa_1d1/15-min"
                  target="_blank"
                  rel="noreferrer nofollow"
                >
                  <FormattedMessage id="pricingPage.demoLink" />
                </a>
              ),
            }}
          />
        </motion.p>
      </div>

      <PricingModel />

      <motion.p variants={FADE_IN_MOVE_Y_CONTROLLED} css={styles.mobileDemo}>
        <FormattedMessage
          id="pricingPage.demo"
          values={{
            link: (
              <a
                href="https://app.apollo.io/#/meet/zeljko_prsa_1d1/15-min"
                target="_blank"
                rel="noreferrer nofollow"
              >
                <FormattedMessage id="pricingPage.demoLink" />
              </a>
            ),
          }}
        />
      </motion.p>
    </motion.article>
  );
};
