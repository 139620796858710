import { css } from '@emotion/react';
import { breakpoints } from 'style/variables';

export const root = css`
  margin-bottom: 60px;

  @media ${breakpoints.medium} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column: 7 / span 6;
    column-gap: 32px;
    margin-bottom: 0;
  }
`;

export const item = css`
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 12px 0;
  font-size: 16px;
  line-height: 24px;
  color: hsl(var(--color-grayscale-1));
  border-top: 1px solid hsl(var(--color-borderDark-5));

  :first-of-type {
    border-top: none;
  }

  @media ${breakpoints.medium} {
    :nth-child(2) {
      border-top: none;
    }

    :nth-last-child(2),
    :last-of-type {
      padding-bottom: 0;
    }
  }
`;

export const check = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  min-width: 32px;
  height: 32px;
  border-radius: 50%;
  background: hsl(var(--color-success-10));
`;
