import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { Navbar, Footer } from 'modules/app';

import * as styles from './styles/Root.styles';
import { PricingModels } from './components/PricingModels';

export const PricingPage: React.FC<RouteComponentProps> = () => {
  return (
    <article css={styles.root}>
      <Navbar negative />
      <section css={styles.wrapper}>
        <PricingModels />
      </section>
      <Footer />
    </article>
  );
};
